
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ModelObjectFamilyMenu'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly objectId?: string
  @Prop({ default: () => {} }) readonly menuProps!: any

  visible = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get modelObject () {
    return this.objectId ? this.modelModule.objects[this.objectId] : undefined
  }

  get disabled () {
    return !this.modelObjectFamily.length || this.menuProps?.disabled === true
  }

  get modelObjectFamily () {
    if (!this.objectId) {
      return []
    }
    const parentIds = this.modelModule.objects[this.objectId]?.parentIds ?? []
    return parentIds.flatMap(o => {
      const object = this.modelModule.objects[o]
      if (object) {
        let heading: string
        let text: string
        if (object.type === 'root') {
          heading = 'Inside Domain'
          text = this.domainModule.domains[object.domainId]?.name
        } else {
          heading = `Inside ${object.type.slice(0, 1).toUpperCase() + object.type.slice(1)}`
          text = object.name || `${object.type.slice(0, 1).toLocaleUpperCase()}${object.type.slice(1)}`
        }
        return {
          heading,
          icon: object.icon ? iconUrlForTheme(object.icon) : undefined,
          id: object.id,
          text
        }
      } else {
        return []
      }
    })
  }

  @Watch('visible')
  onVisibleChanged (visible: boolean, prevVisible: boolean) {
    if (visible && !prevVisible && this.currentLandscapeId && this.modelObject) {
      analytics.modelObjectFamilyMenu.track(this, {
        landscapeId: [this.currentLandscapeId],
        modelObjectType: this.modelObject.type,
        organizationId: [this.currentOrganizationId]
      })
    }
  }
}
