import * as analytics from '@icepanel/app-analytics'

import { diagramType } from '@/modules/diagram/helpers/analytics-properties'
import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import {
  modelConnectionDescriptionLength,
  modelConnectionDirection,
  modelConnectionNameLength,
  modelConnectionStatus,
  modelConnectionTechnologyCount,
  modelConnectionTechnologyNames,
  modelConnectionUpdateDescriptionLength,
  modelConnectionUpdateDirection,
  modelConnectionUpdateNameLength,
  modelConnectionUpdateStatus,
  modelConnectionUpdateTechnologyCount,
  modelConnectionUpdateTechnologyNames,
  modelDependenciesSearchQuery,
  modelDependenciesSearchType,
  modelObjectCount,
  modelObjectDescriptionLength,
  modelObjectDetail,
  modelObjectDiagramCount,
  modelObjectExternal,
  modelObjectIconName,
  modelObjectLinkCount,
  modelObjectLinkType,
  modelObjectNameLength,
  modelObjectParent,
  modelObjectsMenuType,
  modelObjectStatus,
  modelObjectTagCount,
  modelObjectTeamOnlyEditing,
  modelObjectTechnologyCount,
  modelObjectTechnologyNames,
  modelObjectType,
  modelObjectTypes,
  modelObjectUpdateExternal,
  modelObjectUpdateIconName,
  modelObjectUpdateNameLength,
  modelObjectUpdateParent,
  modelObjectUpdateStatus,
  modelObjectUpdateTeamOnlyEditing,
  modelObjectUpdateTechnologyCount,
  modelObjectUpdateTechnologyNames,
  modelObjectUpdateType,
  modelStatus,
  modelTechnologyName,
  modelTechnologyUrl,
  modelType
} from './analytics-properties'

/**
 * Screens
 */

export const modelDependenciesScreen = new analytics.Event({
  displayName: 'Model dependencies screen',
  name: 'model_dependencies_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
export const modelObjectsScreen = new analytics.Event({
  displayName: 'Model objects screen',
  name: 'model_objects_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const modelObjectDependenciesListScreen = new analytics.Event({
  description: 'Shown when selecting an object and clicking the connections tab in the right hand panel',
  displayName: 'Model object dependencies list screen',
  name: 'model_object_dependencies_list_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
export const modelObjectConnectionsListScreen = new analytics.Event({
  description: 'Shown on the landscape dependencies tab when selecting a dependency between two objects and opening the right hand panel',
  displayName: 'Model object connections list screen',
  name: 'model_object_connections_list_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const modelConnectionDeleteDialog = new analytics.Event({
  displayName: 'Model connection delete dialog',
  name: 'model_connection_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const modelConnectionReassignDialog = new analytics.Event({
  displayName: 'Model connection reassign dialog',
  name: 'model_connection_reassign_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const modelConnectionFlipDialog = new analytics.Event({
  displayName: 'Model connection flip dialog',
  name: 'model_connection_flip_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const modelObjectDeleteDialog = new analytics.Event({
  displayName: 'Model object delete dialog',
  name: 'model_object_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  modelObjectCount,
  modelObjectTypes,
  organizationId
})
export const modelObjectParentUpdateDialog = new analytics.Event({
  displayName: 'Model object parent update dialog',
  name: 'model_object_parent_update_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  modelObjectTypes,
  organizationId
})
export const modelObjectTypeUpdateDialog = new analytics.Event({
  displayName: 'Model object type update dialog',
  name: 'model_object_type_update_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  modelObjectType,
  organizationId
})

/**
 * Menus
 */

export const modelInDiagramsMenu = new analytics.Event({
  displayName: 'Model in diagrams menu',
  name: 'model_in_diagrams_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  modelType,
  organizationId
})
export const modelInFlowsMenu = new analytics.Event({
  displayName: 'Model in flows menu',
  name: 'model_in_flows_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  modelType,
  organizationId
})
export const modelObjectsMenu = new analytics.Event({
  displayName: 'Model objects menu',
  name: 'model_objects_menu',
  tags: [analytics.Tags.Menu]
}, {
  diagramType,
  landscapeId,
  modelObjectsMenuType,
  organizationId
})
export const modelObjectFamilyMenu = new analytics.Event({
  displayName: 'Model object family menu',
  name: 'model_object_family_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
export const modelFilterMenu = new analytics.Event({
  displayName: 'Model filter menu',
  name: 'model_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const modelStatusFilterMenu = new analytics.Event({
  displayName: 'Model status filter menu',
  name: 'model_status_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const modelTypeFilterMenu = new analytics.Event({
  displayName: 'Model type filter menu',
  name: 'model_type_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const modelDetailsFilterMenu = new analytics.Event({
  displayName: 'Model details filter menu',
  name: 'model_details_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const modelObjectGroupsMenu = new analytics.Event({
  displayName: 'Model object groups menu',
  name: 'model_object_groups_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})

/**
 * Links
 */

export const modelTechnologyWebsiteLink = new analytics.Event({
  displayName: 'Model technology website link',
  name: 'model_technology_website_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  modelTechnologyName,
  modelTechnologyUrl,
  modelType,
  organizationId
})
export const modelTechnologyDocsLink = new analytics.Event({
  displayName: 'Model technology docs link',
  name: 'model_technology_docs_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  modelTechnologyName,
  modelTechnologyUrl,
  modelType,
  organizationId
})
export const modelTechnologyUpdatesLink = new analytics.Event({
  displayName: 'Model technology updates link',
  name: 'model_technology_updates_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  modelTechnologyName,
  modelTechnologyUrl,
  modelType,
  organizationId
})

/**
 * Events
 */

export const modelObjectUpdate = new analytics.Event({
  displayName: 'Model object update',
  name: 'model_object_update'
}, {
  landscapeId,
  modelObjectDescriptionLength,
  modelObjectDiagramCount,
  modelObjectExternal,
  modelObjectIconName,
  modelObjectLinkCount,
  modelObjectNameLength,
  modelObjectParent,
  modelObjectStatus,
  modelObjectTagCount,
  modelObjectTeamOnlyEditing,
  modelObjectTechnologyCount,
  modelObjectTechnologyNames,
  modelObjectType,
  modelObjectUpdateExternal,
  modelObjectUpdateIconName,
  modelObjectUpdateNameLength,
  modelObjectUpdateParent,
  modelObjectUpdateStatus,
  modelObjectUpdateTeamOnlyEditing,
  modelObjectUpdateTechnologyCount,
  modelObjectUpdateTechnologyNames,
  modelObjectUpdateType,
  organizationId
})
export const modelConnectionUpdate = new analytics.Event({
  displayName: 'Model connection update',
  name: 'model_connection_update'
}, {
  landscapeId,
  modelConnectionDescriptionLength,
  modelConnectionDirection,
  modelConnectionNameLength,
  modelConnectionStatus,
  modelConnectionTechnologyCount,
  modelConnectionTechnologyNames,
  modelConnectionUpdateDescriptionLength,
  modelConnectionUpdateDirection,
  modelConnectionUpdateNameLength,
  modelConnectionUpdateStatus,
  modelConnectionUpdateTechnologyCount,
  modelConnectionUpdateTechnologyNames,
  organizationId
})
export const modelConnectionReassignNew = new analytics.Event({
  displayName: 'Model connection reassign new',
  name: 'model_connection_reassign_new'
}, {
  landscapeId,
  organizationId
})
export const modelConnectionReassignModel = new analytics.Event({
  displayName: 'Model connection reassign model',
  name: 'model_connection_reassign_model'
}, {
  landscapeId,
  organizationId
})
export const modelConnectionFlipModel = new analytics.Event({
  displayName: 'Model connection flip model',
  name: 'model_connection_flip_model'
}, {
  landscapeId,
  organizationId
})
export const modelConnectionFlipNew = new analytics.Event({
  displayName: 'Model connection flip new',
  name: 'model_connection_flip_new'
}, {
  landscapeId,
  organizationId
})
export const modelObjectLinkOpen = new analytics.Event({
  displayName: 'Model object link open',
  name: 'model_object_link_open'
}, {
  landscapeId,
  modelObjectLinkType,
  organizationId
})
export const modelTechnologyExpand = new analytics.Event({
  displayName: 'Model technology expand',
  name: 'model_technology_expand'
}, {
  landscapeId,
  modelTechnologyName,
  modelType,
  organizationId
})
export const modelTechnologyDescriptionExpand = new analytics.Event({
  displayName: 'Model technology description expand',
  name: 'model_technology_description_expand'
}, {
  landscapeId,
  modelTechnologyName,
  modelType,
  organizationId
})
export const modelStatusHide = new analytics.Event({
  displayName: 'Model status hide',
  name: 'model_status_hide'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusUnhide = new analytics.Event({
  displayName: 'Model status unhide',
  name: 'model_status_unhide'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusFocus = new analytics.Event({
  displayName: 'Model status focus',
  name: 'model_status_focus'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusUnfocus = new analytics.Event({
  displayName: 'Model status unfocus',
  name: 'model_status_unfocus'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusPin = new analytics.Event({
  displayName: 'Model status pin',
  name: 'model_status_pin'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusUnpin = new analytics.Event({
  displayName: 'Model status unpin',
  name: 'model_status_unpin'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusPinAll = new analytics.Event({
  displayName: 'Model status pin all',
  name: 'model_status_pin_all'
}, {
  landscapeId,
  organizationId
})
export const modelStatusUnpinAll = new analytics.Event({
  displayName: 'Model status unpin all',
  name: 'model_status_unpin_all'
}, {
  landscapeId,
  organizationId
})
export const modelStatusFilter = new analytics.Event({
  displayName: 'Model status filter',
  name: 'model_status_filter'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelStatusFilterExclude = new analytics.Event({
  displayName: 'Model status filter exclude',
  name: 'model_status_filter_exclude'
}, {
  landscapeId,
  modelStatus,
  organizationId
})
export const modelTypeFilterInclude = new analytics.Event({
  displayName: 'Model type filter include',
  name: 'model_type_filter_include'
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
export const modelTypeFilterExclude = new analytics.Event({
  displayName: 'Model type filter exclude',
  name: 'model_type_filter_exclude'
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
export const modelExternalFilter = new analytics.Event({
  displayName: 'Model external filter',
  name: 'model_external_filter'
}, {
  landscapeId,
  modelObjectExternal,
  organizationId
})
export const modelExternalFilterExclude = new analytics.Event({
  displayName: 'Model external filter exclude',
  name: 'model_external_filter_exclude'
}, {
  landscapeId,
  modelObjectExternal,
  organizationId
})
export const modelDetailFilterInclude = new analytics.Event({
  displayName: 'Model detail filter include',
  name: 'model_detail_filter_include'
}, {
  landscapeId,
  modelObjectDetail,
  organizationId
})
export const modelDetailFilterExclude = new analytics.Event({
  displayName: 'Model detail filter exclude',
  name: 'model_detail_filter_exclude'
}, {
  landscapeId,
  modelObjectDetail,
  organizationId
})
export const modelDependenciesSearch = new analytics.Event({
  displayName: 'Model dependencies search',
  name: 'model_dependencies_search'
}, {
  landscapeId,
  modelDependenciesSearchQuery,
  modelDependenciesSearchType,
  organizationId
})

/**
 * Hidden
 */

export const modelOtherDiagramsMenuHidden = new analytics.Event({
  displayName: 'Model other diagrams menu',
  name: 'model_other_diagrams_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const modelObjectIconPickerMenuHidden = new analytics.Event({
  displayName: 'Model object icon picker menu',
  hidden: true,
  name: 'model_object_icon_picker_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  modelObjectType,
  organizationId
})
