import * as farEye from '@fortawesome/free-regular-svg-icons/faEye'
import * as fasFileAlt from '@fortawesome/free-regular-svg-icons/faFileAlt'
import * as farSquare from '@fortawesome/free-regular-svg-icons/faSquare'
import * as fasArrowRight from '@fortawesome/free-solid-svg-icons/faArrowRight'
import * as fasBan from '@fortawesome/free-solid-svg-icons/faBan'
import * as fasBook from '@fortawesome/free-solid-svg-icons/faBook'
import * as fasBuilding from '@fortawesome/free-solid-svg-icons/faBuilding'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretRight from '@fortawesome/free-solid-svg-icons/faCaretRight'
import * as fasCheckSquare from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import * as fasCrosshairs from '@fortawesome/free-solid-svg-icons/faCrosshairs'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasEraser from '@fortawesome/free-solid-svg-icons/faEraser'
import * as fasExchangeAlt from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as fasFilter from '@fortawesome/free-solid-svg-icons/faFilter'
import * as fasGlobe from '@fortawesome/free-solid-svg-icons/faGlobe'
import * as fasHistory from '@fortawesome/free-solid-svg-icons/faHistory'
import * as fasLayerGroup from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import * as fasList from '@fortawesome/free-solid-svg-icons/faList'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasSearchPlus from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import * as fasSortAlphaDown from '@fortawesome/free-solid-svg-icons/faSortAlphaDown'
import * as fasSortAlphaUp from '@fortawesome/free-solid-svg-icons/faSortAlphaUp'
import * as fasSortNumericDown from '@fortawesome/free-solid-svg-icons/faSortNumericDown'
import * as fasSortNumericUp from '@fortawesome/free-solid-svg-icons/faSortNumericUp'
import * as fasSpinner from '@fortawesome/free-solid-svg-icons/faSpinner'
import * as fasThumbsDown from '@fortawesome/free-solid-svg-icons/faThumbsDown'
import * as fasThumbsUp from '@fortawesome/free-solid-svg-icons/faThumbsUp'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasVectorSquare from '@fortawesome/free-solid-svg-icons/faVectorSquare'

export default [
  farEye.definition,
  farSquare.definition,
  fasArrowRight.definition,
  fasBan.definition,
  fasBook.definition,
  fasBuilding.definition,
  fasCaretDown.definition,
  fasCaretRight.definition,
  fasCheckSquare.definition,
  fasCrosshairs.definition,
  fasEllipsisH.definition,
  fasEraser.definition,
  fasExchangeAlt.definition,
  fasExclamationTriangle.definition,
  fasExternalLinkAlt.definition,
  fasFileAlt.definition,
  fasFilter.definition,
  fasGlobe.definition,
  fasHistory.definition,
  fasLayerGroup.definition,
  fasList.definition,
  fasPencilAlt.definition,
  fasPlus.definition,
  fasSearchPlus.definition,
  fasSortAlphaDown.definition,
  fasSortAlphaUp.definition,
  fasSortNumericDown.definition,
  fasSortNumericUp.definition,
  fasSpinner.definition,
  fasThumbsDown.definition,
  fasThumbsUp.definition,
  fasVectorSquare.definition,
  fasTimes.definition
] as const
