import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface INavigationModule {
  sidebarExpanded: boolean
}

const name = 'navigation'

const sidebarExpanded = localStorage.getItem('showWideSidebar')

@Module({
  name,
  namespaced: true
})
export class NavigationModule extends VuexModule implements INavigationModule {
  static namespace = name

  sidebarExpanded = !sidebarExpanded || sidebarExpanded === 'true'

  get expanded () {
    return this.sidebarExpanded ?? true
  }

  @Mutation
  setSidebarExpanded (expanded: boolean) {
    this.sidebarExpanded = expanded
    localStorage.setItem('showWideSidebar', this.sidebarExpanded ? 'true' : 'false')
  }
}
