import {
  CodeRepoLink,
  CodeRepoLinkPartial,
  DiagramContent,
  DiagramObjectConnector,
  ModelConnection,
  ModelConnectionDiagram,
  ModelConnectionPartial,
  ModelConnectionRequired,
  ModelConnectionsService,
  ModelObject,
  ModelObjectFilter,
  ModelObjectLink,
  ModelObjectPartial,
  ModelObjectRequired,
  ModelObjectsService,
  ModelObjectTechnology,
  SocketClientModelConnectionsSubscribeBody,
  SocketClientModelObjectsSubscribeBody,
  UrlLinkPartial
} from '@icepanel/platform-api-client'
import omit from 'lodash/omit'
import objectHash from 'object-hash'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import * as sort from '@/helpers/sort'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface IModelModule {
  objectsCurrent: Record<string, ModelObject>
  objectsCache: Record<string, ModelObject> | null
  objectsCommit: Record<string, number>
  objectHandles: Record<string, string>

  connectionsCurrent: Record<string, ModelConnection>
  connectionsCache: Record<string, ModelConnection> | null
  connectionsCommit: Record<string, number>

  objectsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
  connectionsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>

  objectLinksSyncStatus: Status
}

const name = 'model'

@Module({
  name,
  namespaced: true
})
export class ModelModule extends VuexModule implements IModelModule {
  static namespace = name

  objectsCurrent: Record<string, ModelObject> = {}
  objectsCache: Record<string, ModelObject> | null = null
  objectsCommit: Record<string, number> = {}
  objectHandles: Record<string, string> = {}

  connectionsCurrent: Record<string, ModelConnection> = {}
  connectionsCache: Record<string, ModelConnection> | null = null
  connectionsCommit: Record<string, number> = {}

  objectsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()
  connectionsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()

  objectLinksSyncStatus = new Status()

  get urlLinkId () {
    return (link: UrlLinkPartial) => objectHash({ url: link.url })
  }

  get codeRepoLinkId () {
    const linkIdOmitKeys: (keyof CodeRepoLink)[] = [
      'customName',
      'id',
      'index',
      'name',
      'status',
      'technologies',
      'url'
    ]
    return (link: CodeRepoLinkPartial) => objectHash(omit(link, linkIdOmitKeys))
  }

  get generateObjectId () {
    return () => getFirestoreId('model-object')
  }

  get generateConnectionId () {
    return () => getFirestoreId('model-connection')
  }

  get generateObject () {
    return (landscapeId: string, versionId: string, props: ModelObjectRequired, id = this.generateObjectId()): { object: ModelObject, objectUpsert: ModelObjectRequired & ModelObjectPartial } => {
      const commit = typeof this.objectsCommit[id] === 'number' ? this.objectsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      const links = Object
        .values(props.links || {})
        .sort(sort.index)
        .reduce<Record<string, ModelObjectLink>>((p, c, i) => {
          let id: string
          let link: ModelObjectLink
          if (c.type === 'url') {
            id = this.urlLinkId(c)
            link = {
              name: c.url,
              ...c,
              id,
              index: i
            }
          } else {
            id = this.codeRepoLinkId(c)
            link = {
              name: '',
              status: 'valid',
              technologies: [],
              url: '',
              ...c,
              id,
              index: i
            }
          }
          return {
            ...p,
            [id]: link
          }
        }, {})
      return {
        object: {
          caption: '',
          description: '',
          domainId: '',
          external: false,
          groupIds: [],
          handleId: defaultHandleId,
          icon: null,
          labels: {},
          parentIds: [],
          status: 'live',
          tagIds: [],
          teamIds: [],
          teamOnlyEditing: false,
          technologies: {},
          ...props,
          childDiagramIds: [],
          childIds: [],
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          diagrams: {},
          flows: {},
          id,
          landscapeId,
          links,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        objectUpsert: {
          caption: '',
          description: '',
          external: false,
          handleId: defaultHandleId,
          icon: null,
          labels: {},
          tagIds: [],
          teamIds: [],
          technologies: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateConnection () {
    return (landscapeId: string, versionId: string, props: ModelConnectionRequired, id = this.generateConnectionId()): { connection: ModelConnection, connectionUpsert: ModelConnectionRequired & ModelConnectionPartial } => {
      const commit = typeof this.connectionsCommit[id] === 'number' ? this.connectionsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        connection: {
          description: '',
          handleId: defaultHandleId,
          labels: {},
          status: 'live',
          tagIds: [],
          technologies: {},
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          diagrams: {},
          flows: {},
          id,
          landscapeId,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        connectionUpsert: {
          description: '',
          handleId: defaultHandleId,
          labels: {},
          tagIds: [],
          technologies: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateObjectCommit () {
    return (id: string, props: Omit<ModelObjectPartial, 'commit'>, existing?: ModelObject, clone = true): { object: ModelObject, objectUpdate: ModelObjectPartial } => {
      let object = existing || this.objects[id]
      if (clone) {
        object = structuredClone(object)
      }
      if (object) {
        const commit = object.commit + 1
        Object.assign(object, props)
        object.updatedAt = new Date().toISOString()
        object.updatedBy = 'user'
        object.updatedById = this.context.rootState.user.user?.id || ''

        let links: Record<string, ModelObjectLink> | undefined
        if (props.links) {
          links = Object
            .values(props.links)
            .sort(sort.index)
            .reduce<Record<string, ModelObjectLink>>((p, c, i) => {
              let id: string
              let link: ModelObjectLink
              if (c.type === 'url') {
                id = this.urlLinkId(c)
                const lastLink = object.links[id] ? object.links[id] : undefined
                link = {
                  name: c.url,
                  ...lastLink,
                  ...c,
                  id,
                  index: i
                }
              } else {
                id = this.codeRepoLinkId(c)
                const lastLink = object.links[id] ? object.links[id] : undefined
                link = {
                  name: '',
                  status: 'valid',
                  technologies: [],
                  url: '',
                  ...lastLink,
                  ...c,
                  id,
                  index: i
                }
              }
              return {
                ...p,
                [id]: link
              }
            }, {})
        }

        Object.assign(object, props, {
          commit,
          links: links || object.links
        })

        return {
          object,
          objectUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find object ${id}`)
      }
    }
  }

  get generateConnectionCommit () {
    return (id: string, props: Omit<ModelConnectionPartial, 'commit'>, existing?: ModelConnection, clone = true): { connection: ModelConnection, connectionUpdate: ModelConnectionPartial } => {
      let connection = existing || this.connections[id]
      if (clone) {
        connection = structuredClone(connection)
      }
      if (connection) {
        const commit = connection.commit + 1
        connection.updatedAt = new Date().toISOString()
        connection.updatedBy = 'user'
        connection.updatedById = this.context.rootState.user.user?.id || ''

        Object.assign(connection, props, {
          commit
        })

        return {
          connection,
          connectionUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find connection ${id}`)
      }
    }
  }

  get objects () {
    const diagramContentStaged: DiagramContent | undefined = this.context.rootGetters['diagram/diagramContentStaged']

    if (this.objectsCache) {
      return this.objectsCache
    } else if (diagramContentStaged?.tasksProposed.some(o => o.task.type.includes('model-object'))) {
      const objectsCurrent = { ...this.objectsCurrent }

      diagramContentStaged.tasksProposed.forEach(o => {
        if (o.task.type === 'model-object-create' && !objectsCurrent[o.task.id]) {
          const { object } = this.generateObject(diagramContentStaged.landscapeId, diagramContentStaged.versionId, o.task.props, o.task.id)

          const diagramConnection = Object.values(diagramContentStaged.objects).find(o => o.modelId === object.id)
          if (diagramConnection) {
            object.diagrams[diagramContentStaged.id] = {
              id: diagramContentStaged.id,
              objectId: diagramConnection.id
            }
          }

          objectsCurrent[object.id] = object
        } else if (o.task.type === 'model-object-update' && objectsCurrent[o.task.id]) {
          const { object } = this.generateObjectCommit(o.task.id, o.task.props, objectsCurrent[o.task.id])
          objectsCurrent[object.id] = object
        } else if (o.task.type === 'model-object-delete' && objectsCurrent[o.task.id]) {
          delete objectsCurrent[o.task.id]
        }
      })

      return objectsCurrent
    } else {
      return this.objectsCurrent
    }
  }

  get connections () {
    const diagramContentStaged: DiagramContent | undefined = this.context.rootGetters['diagram/diagramContentStaged']

    if (this.connectionsCache) {
      return this.connectionsCache
    } else if (diagramContentStaged?.tasksProposed.some(o => o.task.type.includes('model-connection'))) {
      const connectionsCurrent = { ...this.connectionsCurrent }

      diagramContentStaged.tasksProposed.forEach(o => {
        if (o.task.type === 'model-connection-create' && !connectionsCurrent[o.task.id]) {
          const { connection } = this.generateConnection(diagramContentStaged.landscapeId, diagramContentStaged.versionId, o.task.props, o.task.id)
          connectionsCurrent[connection.id] = connection
        } else if (o.task.type === 'model-connection-update' && connectionsCurrent[o.task.id]) {
          const { connection } = this.generateConnectionCommit(o.task.id, o.task.props, connectionsCurrent[o.task.id])
          connectionsCurrent[connection.id] = connection
        } else if (o.task.type === 'model-connection-delete' && connectionsCurrent[o.task.id]) {
          delete connectionsCurrent[o.task.id]
        }

        if (o.task.type === 'model-connection-create' || o.task.type === 'model-connection-update') {
          const id = o.task.id
          const diagramConnection = Object.values(diagramContentStaged.connections).find(c => c.modelId === id)
          const originModelId = diagramConnection?.originId ? diagramContentStaged.objects[diagramConnection.originId]?.modelId : undefined
          const targetModelId = diagramConnection?.targetId ? diagramContentStaged.objects[diagramConnection.targetId]?.modelId : undefined

          if (diagramConnection && originModelId && targetModelId) {
            connectionsCurrent[id].diagrams[diagramContentStaged.id] = {
              connectionId: diagramConnection.id,
              id: diagramContentStaged.id,
              originModelId,
              targetModelId
            }
          }
        }
      })

      return connectionsCurrent
    } else {
      return this.connectionsCurrent
    }
  }

  get technologies () {
    const technologies: Record<string, ModelObjectTechnology> = {}
    Object.values(this.objects).forEach(o => {
      Object.values(o.technologies).forEach(t => {
        technologies[t.id] = t
      })
    })
    Object.values(this.connections).forEach(o => {
      Object.values(o.technologies).forEach(t => {
        technologies[t.id] = t
      })
    })
    return technologies
  }

  get technologyCount () {
    const technologyCount: Record<string, number> = {}
    Object.values(this.objects).forEach(o => {
      Object.keys(o.technologies).forEach(id => {
        if (!technologyCount[id]) {
          technologyCount[id] = 0
        }
        technologyCount[id]++
      })
    })
    Object.values(this.connections).forEach(o => {
      Object.keys(o.technologies).forEach(id => {
        if (!technologyCount[id]) {
          technologyCount[id] = 0
        }
        technologyCount[id]++
      })
    })
    return technologyCount
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setObjects (objects: ModelObject[] | Record<string, ModelObject>) {
    this.objectsCurrent = {}
    this.objectHandles = {}
    this.objectsCommit = {}

    if (objects instanceof Array) {
      Object.values(objects).forEach(o => {
        Vue.set(this.objectsCurrent, o.id, o)
        Vue.set(this.objectHandles, o.handleId, o.id)
        Vue.set(this.objectsCommit, o.id, o.commit)
      })
    } else {
      this.objectsCurrent = objects

      Object.values(this.objectsCurrent).forEach(o => {
        Vue.set(this.objectHandles, o.handleId, o.id)
        Vue.set(this.objectsCommit, o.id, o.commit)
      })
    }
  }

  @Mutation
  setObjectsCache (objectsCache: Record<string, ModelObject> | null) {
    this.objectsCache = objectsCache
  }

  @Mutation
  setObject (object: ModelObject) {
    Vue.set(this.objectsCurrent, object.id, object)
    Vue.set(this.objectHandles, object.handleId, object.id)
    Vue.set(this.objectsCommit, object.id, object.commit)
  }

  @Mutation
  setObjectVersion (object: ModelObject) {
    if (
      this.objectsCommit[object.id] === undefined ||
      object.commit > this.objectsCommit[object.id] ||
      (object.commit === this.objectsCommit[object.id] && this.objectsCurrent[object.id] && object.version > this.objectsCurrent[object.id].version)
    ) {
      Vue.set(this.objectsCurrent, object.id, object)
      Vue.set(this.objectHandles, object.handleId, object.id)
      Vue.set(this.objectsCommit, object.id, object.commit)
    }
  }

  @Mutation
  removeObject (object: string | Pick<ModelObject, 'id' | 'commit'>) {
    if (typeof object === 'string') {
      Vue.delete(this.objectsCurrent, object)
      Vue.delete(this.objectHandles, object)
    } else if (object.commit >= this.objectsCommit[object.id]) {
      Vue.delete(this.objectsCurrent, object.id)
      Vue.delete(this.objectHandles, object.id)
      Vue.set(this.objectsCommit, object.id, object.commit)
    }
  }

  @Mutation
  setConnections (connections: ModelConnection[] | Record<string, ModelConnection>) {
    this.connectionsCurrent = {}
    this.connectionsCommit = {}

    Object
      .values(connections)
      .forEach(o => {
        Vue.set(this.connectionsCurrent, o.id, o)
        Vue.set(this.connectionsCommit, o.id, o.commit)
      })
  }

  @Mutation
  setConnectionsCache (connectionsCache: Record<string, ModelConnection> | null) {
    this.connectionsCache = connectionsCache
  }

  @Mutation
  setConnection (connection: ModelConnection) {
    Vue.set(this.connectionsCurrent, connection.id, connection)
    Vue.set(this.connectionsCommit, connection.id, connection.commit)
  }

  @Mutation
  setConnectionVersion (connection: ModelConnection) {
    if (
      this.connectionsCommit[connection.id] === undefined ||
      connection.commit > this.connectionsCommit[connection.id] ||
      (connection.commit === this.connectionsCommit[connection.id] && this.connectionsCurrent[connection.id] && connection.version > this.connectionsCurrent[connection.id].version)
    ) {
      Vue.set(this.connectionsCurrent, connection.id, connection)
      Vue.set(this.connectionsCommit, connection.id, connection.commit)
    }
  }

  @Mutation
  setConnectionDiagrams ({ modelConnectionDiagramAdd, modelConnectionDiagramRemove }: { modelConnectionDiagramAdd: Record<string, ModelConnectionDiagram>, modelConnectionDiagramRemove: Record<string, string> }) {
    Object.entries(modelConnectionDiagramAdd).forEach(([id, diagram]) => {
      if (this.connectionsCurrent[id]) {
        this.connectionsCurrent[id] = {
          ...this.connectionsCurrent[id],
          diagrams: {
            ...this.connectionsCurrent[id].diagrams,
            [diagram.id]: diagram
          },
          version: this.connectionsCurrent[id].version + 1
        }
      }
    })
    Object.entries(modelConnectionDiagramRemove).forEach(([id, diagramId]) => {
      if (this.connectionsCurrent[id]) {
        delete this.connectionsCurrent[id].diagrams[diagramId]
        this.connectionsCurrent[id] = {
          ...this.connectionsCurrent[id],
          version: this.connectionsCurrent[id].version + 1
        }
      }
    })
  }

  @Mutation
  removeConnection (connection: string | Pick<ModelConnection, 'id' | 'commit'>) {
    if (typeof connection === 'string') {
      Vue.delete(this.connectionsCurrent, connection)
    } else if (connection.commit >= this.connectionsCommit[connection.id]) {
      Vue.delete(this.connectionsCurrent, connection.id)
      Vue.set(this.connectionsCommit, connection.id, connection.commit)
    }
  }

  @Mutation
  setObjectLinksSyncStatus (...params: Parameters<typeof this.objectLinksSyncStatus.set>) {
    this.objectLinksSyncStatus.set(...params)
  }

  @Mutation
  setObjectsSubscriptionStatus (...params: Parameters<typeof this.objectsSubscriptionStatus.set>) {
    this.objectsSubscriptionStatus.set(...params)
  }

  @Mutation
  setConnectionsSubscriptionStatus (...params: Parameters<typeof this.connectionsSubscriptionStatus.set>) {
    this.connectionsSubscriptionStatus.set(...params)
  }

  @Mutation
  objectsUnsubscribe () {
    this.objectsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.objectsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.objectsSubscriptionStatus.set(Status.idle())
  }

  @Mutation
  connectionsUnsubscribe () {
    this.connectionsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.connectionsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.connectionsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async objectsSubscribe (body: SocketClientModelObjectsSubscribeBody & { reconnect: boolean }) {
    this.objectsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.objectsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setObjectsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: ModelObject[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('model-objects-subscribe', {
        initialValue: true,
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const objectInitialValueListener: ServerEvents['model-object-initial-value'] = ({ finalValue, modelObjects, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...modelObjects)

              if (finalValue) {
                this.context.commit('setObjects', initialValue)

                this.context.commit('setObjectsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const objectAddedListener: ServerEvents['model-object-added'] = ({ modelObject, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setObjectVersion', modelObject)
            }
            if (modelObject.createdById && modelObject.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const objectModifiedListener: ServerEvents['model-object-modified'] = ({ modelObject, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setObjectVersion', modelObject)
            }
            if (modelObject.updatedById && modelObject.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const objectRemovedListener: ServerEvents['model-object-removed'] = ({ modelObject, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeObject', modelObject)
            }
            if (modelObject.deletedById && modelObject.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('model-object-initial-value', objectInitialValueListener)
            this.socket.off('model-object-added', objectAddedListener)
            this.socket.off('model-object-modified', objectModifiedListener)
            this.socket.off('model-object-removed', objectRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('model-objects-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('objects unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('model-object-initial-value', objectInitialValueListener)
          this.socket.on('model-object-added', objectAddedListener)
          this.socket.on('model-object-modified', objectModifiedListener)
          this.socket.on('model-object-removed', objectRemovedListener)

          this.context.commit('setObjectsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Object subscription not provided'
          this.context.commit('setObjectsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async connectionsSubscribe (body: SocketClientModelConnectionsSubscribeBody & { reconnect: boolean }) {
    this.connectionsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.connectionsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setConnectionsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: ModelConnection [] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('model-connections-subscribe', {
        initialValue: true,
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const connectionInitialValueListener: ServerEvents['model-connection-initial-value'] = ({ finalValue, modelConnections, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...modelConnections)

              if (finalValue) {
                this.context.commit('setConnections', initialValue)

                this.context.commit('setConnectionsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const connectionAddedListener: ServerEvents['model-connection-added'] = ({ modelConnection, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setConnectionVersion', modelConnection)
            }
            if (modelConnection.createdById && modelConnection.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const connectionModifiedListener: ServerEvents['model-connection-modified'] = ({ modelConnection, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setConnectionVersion', modelConnection)
            }
            if (modelConnection.updatedById && modelConnection.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const connectionRemovedListener: ServerEvents['model-connection-removed'] = ({ modelConnection, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeConnection', modelConnection)
            }
            if (modelConnection.deletedById && modelConnection.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('model-connection-initial-value', connectionInitialValueListener)
            this.socket.off('model-connection-added', connectionAddedListener)
            this.socket.off('model-connection-modified', connectionModifiedListener)
            this.socket.off('model-connection-removed', connectionRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('model-connections-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('connections unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('model-connection-initial-value', connectionInitialValueListener)
          this.socket.on('model-connection-added', connectionAddedListener)
          this.socket.on('model-connection-modified', connectionModifiedListener)
          this.socket.on('model-connection-removed', connectionRemovedListener)

          this.context.commit('setConnectionsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Connection subscription not provided'
          this.context.commit('setConnectionsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async objectsList ({ landscapeId, versionId, filter }: { landscapeId: string, versionId: string, filter?: ModelObjectFilter }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelObjects } = await ModelObjectsService.modelObjectsList(authorization, landscapeId, versionId, filter)
    this.context.commit('setObjects', modelObjects)
    return modelObjects
  }

  @Action({ rawError: true })
  async objectCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: ModelObjectRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelObject } = await ModelObjectsService.modelObjectCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setObjectVersion', modelObject)
    this.context.commit('team/setTeamModelObjects', { modelObjectHandleId: modelObject.handleId, teamIds: modelObject.teamIds }, { root: true })
    return modelObject
  }

  @Action({ rawError: true })
  async objectUpsert ({ landscapeId, versionId, objectId, props }: { landscapeId: string, versionId: string, objectId: string, props: ModelObjectRequired & ModelObjectPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelObject } = await ModelObjectsService.modelObjectUpsert(authorization, landscapeId, versionId, objectId, props)
    this.context.commit('setObjectVersion', modelObject)
    this.context.commit('team/setTeamModelObjects', { modelObjectHandleId: modelObject.handleId, teamIds: modelObject.teamIds }, { root: true })
    return modelObject
  }

  @Action({ rawError: true })
  async objectUpdate ({ landscapeId, versionId, objectId, props }: { landscapeId: string, versionId: string, objectId: string, props: ModelObjectPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelObject } = await ModelObjectsService.modelObjectUpdate(authorization, landscapeId, versionId, objectId, props)
    this.context.commit('setObjectVersion', modelObject)
    this.context.commit('team/setTeamModelObjects', { modelObjectHandleId: modelObject.handleId, teamIds: modelObject.teamIds }, { root: true })
    return modelObject
  }

  @Action({ rawError: true })
  async objectDelete ({ landscapeId, versionId, objectId }: { landscapeId: string, versionId: string, objectId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await ModelObjectsService.modelObjectDelete(authorization, landscapeId, versionId, objectId)
    this.context.commit('removeObject', { commit, id: objectId })
  }

  @Action({ rawError: true })
  async objectLinksSync ({ landscapeId, versionId, objectId }: { landscapeId: string, versionId: string, objectId: string }) {
    try {
      this.context.commit('setObjectLinksSyncStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { modelObject } = await ModelObjectsService.modelObjectLinksSync(authorization, landscapeId, versionId, objectId)
      this.context.commit('setObject', modelObject)

      this.context.commit('setObjectLinksSyncStatus', Status.success())

      return modelObject
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setObjectLinksSyncStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async objectsExportCsv ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const csv = await ModelObjectsService.modelObjectsExportCsv(authorization, landscapeId, versionId)
    return csv as string
  }

  @Action({ rawError: true })
  async connectionsList ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelConnections } = await ModelConnectionsService.modelConnectionsList(authorization, landscapeId, versionId)
    this.context.commit('setConnections', modelConnections)
    return modelConnections
  }

  @Action({ rawError: true })
  async connectionCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: ModelConnectionRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelConnection } = await ModelConnectionsService.modelConnectionCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setConnectionVersion', modelConnection)
    return modelConnection
  }

  @Action({ rawError: true })
  async connectionUpsert (
    { landscapeId, versionId, connectionId, props, originConnector, targetConnector, updateDiagrams }:
    { landscapeId: string, versionId: string, connectionId: string, props: ModelConnectionRequired & ModelConnectionPartial, originConnector?: NonNullable<DiagramObjectConnector>, targetConnector?: NonNullable<DiagramObjectConnector>, updateDiagrams?: boolean }
  ) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelConnection } = await ModelConnectionsService.modelConnectionUpsert(authorization, landscapeId, versionId, connectionId, props, originConnector, targetConnector, updateDiagrams)
    this.context.commit('setConnectionVersion', modelConnection)
    return modelConnection
  }

  @Action({ rawError: true })
  async connectionUpdate (
    { landscapeId, versionId, connectionId, props, updateDiagrams }:
    { landscapeId: string, versionId: string, connectionId: string, props: ModelConnectionPartial, updateDiagrams?: boolean }
  ) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { modelConnection } = await ModelConnectionsService.modelConnectionUpdate(authorization, landscapeId, versionId, connectionId, props, updateDiagrams)
    this.context.commit('setConnectionVersion', modelConnection)
    return modelConnection
  }

  @Action({ rawError: true })
  async connectionDelete ({ landscapeId, versionId, connectionId }: { landscapeId: string, versionId: string, connectionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await ModelConnectionsService.modelConnectionDelete(authorization, landscapeId, versionId, connectionId)
    this.context.commit('removeConnection', { commit, id: connectionId })
  }

  @Action({ rawError: true })
  async connectionsExportCsv ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const res = await ModelConnectionsService.modelConnectionsExportCsv(authorization, landscapeId, versionId)
    return res
  }
}
