import * as analytics from '@icepanel/app-analytics'
import { ModelConnectionDirection, ModelConnectionStatus, ModelObjectLinkType, ModelObjectStatus, ModelObjectType, ModelStatusType } from '@icepanel/platform-api-client'

export const modelStatus: analytics.Property<ModelStatusType> = {
  displayName: 'Model status',
  name: 'model_status',
  required: true,
  type: 'string'
}
export const modelObjectTypes: analytics.Property<ModelObjectType[]> = {
  displayName: 'Model object types',
  examples: [['actor', 'app', 'group', 'component', 'root', 'store', 'system']],
  name: 'model_object_types',
  required: true,
  type: 'array'
}
export const modelType: analytics.Property<ModelObjectType | 'connection'> = {
  displayName: 'Model object type',
  examples: ['actor', 'app', 'group', 'component', 'root', 'store', 'system', 'connection'],
  name: 'model_object_type',
  required: true,
  type: 'string'
}
export const modelTypes: analytics.Property<(ModelObjectType | 'connection')[]> = {
  displayName: 'Model object types',
  examples: [['actor', 'app', 'group', 'component', 'root', 'store', 'system', 'connection']],
  name: 'model_object_types',
  required: true,
  type: 'array'
}
export const modelTechnologyName: analytics.Property<string> = {
  displayName: 'Model technology name',
  name: 'model_technology_name',
  required: true,
  type: 'string'
}
export const modelTechnologyUrl: analytics.Property<string> = {
  displayName: 'Model technology url',
  name: 'model_technology_url',
  required: true,
  type: 'string'
}
export const modelObjectCount: analytics.Property<number> = {
  displayName: 'Model object count',
  name: 'model_object_count',
  required: true,
  type: 'number'
}
export const modelObjectsMenuType: analytics.Property<ModelObjectType | 'other'> = {
  displayName: 'Model objects menu type',
  examples: ['actor', 'app', 'group', 'component', 'store', 'system', 'other'],
  name: 'model_objects_menu_type',
  required: true,
  type: 'string'
}

export const modelObjectDiagramCount: analytics.Property<number> = {
  displayName: 'Model object diagram count',
  name: 'model_object_diagram_count',
  required: true,
  type: 'number'
}

export const modelObjectDescriptionLength: analytics.Property<number> = {
  displayName: 'Model object description length',
  name: 'model_object_description_length',
  required: true,
  type: 'number'
}
export const modelObjectIconName: analytics.Property<string | null> = {
  displayName: 'Model object icon name',
  name: 'model_object_icon_name',
  required: true,
  type: 'string'
}
export const modelObjectStatus: analytics.Property<ModelObjectStatus> = {
  displayName: 'Model object status',
  name: 'model_object_status',
  required: true,
  type: 'string'
}
export const modelObjectExternal: analytics.Property<boolean> = {
  displayName: 'Model object external',
  name: 'model_object_external',
  required: true,
  type: 'boolean'
}
export const modelObjectLinkCount: analytics.Property<number> = {
  displayName: 'Model object link count',
  name: 'model_object_link_count',
  required: true,
  type: 'number'
}
export const modelObjectTechnologyNames: analytics.Property<string[]> = {
  displayName: 'Model object technology names',
  name: 'model_object_technology_names',
  required: true,
  type: 'array'
}
export const modelObjectTechnologyCount: analytics.Property<number> = {
  displayName: 'Model object technology count',
  name: 'model_object_technology_count',
  required: true,
  type: 'number'
}
export const modelObjectNameLength: analytics.Property<number> = {
  displayName: 'Model object name length',
  name: 'model_object_name_length',
  required: true,
  type: 'number'
}
export const modelObjectTagCount: analytics.Property<number> = {
  displayName: 'Model object tag count',
  name: 'model_object_tag_count',
  required: true,
  type: 'number'
}
export const modelObjectParent: analytics.Property<string | null> = {
  displayName: 'Model object parent',
  name: 'model_object_parent',
  required: true,
  type: 'string'
}
export const modelObjectType: analytics.Property<ModelObjectType> = {
  displayName: 'Model object type',
  examples: ['actor', 'app', 'group', 'component', 'root', 'store', 'system'],
  name: 'model_object_type',
  required: true,
  type: 'string'
}
export const modelObjectDetail: analytics.Property<string> = {
  displayName: 'Model object detail',
  examples: ['detailed_description', 'display_description'],
  name: 'model_object_detail',
  required: true,
  type: 'string'
}
export const modelObjectTeamOnlyEditing: analytics.Property<boolean> = {
  displayName: 'Model object team only editing',
  name: 'model_object_team_only_editing',
  required: true,
  type: 'boolean'
}

export const modelObjectUpdateNameLength: analytics.Property<number | undefined> = {
  displayName: 'Model object update name length',
  name: 'model_object_update_name_length',
  required: false,
  type: 'number'
}
export const modelObjectUpdateTechnologyNames: analytics.Property<string[] | undefined> = {
  displayName: 'Model object update technology names',
  name: 'model_object_update_technology_names',
  required: false,
  type: 'array'
}
export const modelObjectUpdateTechnologyCount: analytics.Property<number | undefined> = {
  displayName: 'Model object update technology count',
  name: 'model_object_update_technology_count',
  required: false,
  type: 'number'
}
export const modelObjectUpdateIconName: analytics.Property<string | null | undefined> = {
  displayName: 'Model object update icon name',
  name: 'model_object_update_icon_name',
  required: false,
  type: 'string'
}
export const modelObjectUpdateParent: analytics.Property<string | null | undefined> = {
  displayName: 'Model object update parent',
  name: 'model_object_update_parent',
  required: false,
  type: 'string'
}
export const modelObjectUpdateType: analytics.Property<ModelObjectType | undefined> = {
  displayName: 'Model object update type',
  examples: ['actor', 'app', 'group', 'component', 'root', 'store', 'system'],
  name: 'model_object_update_type',
  required: false,
  type: 'string'
}
export const modelObjectUpdateStatus: analytics.Property<ModelObjectStatus | undefined> = {
  displayName: 'Model object update status',
  examples: ['live', 'future', 'deprecated', 'removed'],
  name: 'model_object_update_status',
  required: false,
  type: 'string'
}
export const modelObjectUpdateExternal: analytics.Property<boolean | undefined> = {
  displayName: 'Model object update external',
  name: 'model_object_update_external',
  required: false,
  type: 'boolean'
}
export const modelObjectUpdateTeamOnlyEditing: analytics.Property<boolean | undefined> = {
  displayName: 'Model object update team only editing',
  name: 'model_object_update_team_only_editing',
  required: false,
  type: 'boolean'
}

export const modelConnectionNameLength: analytics.Property<number> = {
  displayName: 'Model connection name length',
  name: 'model_connection_name_length',
  required: true,
  type: 'number'
}
export const modelConnectionDescriptionLength: analytics.Property<number> = {
  displayName: 'Model connection description length',
  name: 'model_connection_description_length',
  required: true,
  type: 'number'
}
export const modelConnectionDirection: analytics.Property<ModelConnectionDirection | null> = {
  displayName: 'Model connection direction',
  name: 'model_connection_direction',
  required: true,
  type: 'string'
}
export const modelConnectionStatus: analytics.Property<ModelConnectionStatus> = {
  displayName: 'Model connection status',
  name: 'model_connection_status',
  required: true,
  type: 'string'
}
export const modelConnectionTechnologyNames: analytics.Property<string[]> = {
  displayName: 'Model connection technology names',
  name: 'model_connection_technology_names',
  required: true,
  type: 'array'
}
export const modelConnectionTechnologyCount: analytics.Property<number> = {
  displayName: 'Model connection technology count',
  name: 'model_connection_technology_count',
  required: true,
  type: 'number'
}

export const modelConnectionUpdateNameLength: analytics.Property<number | undefined> = {
  displayName: 'Model connection update name length',
  name: 'model_connection_update_name_length',
  required: false,
  type: 'number'
}
export const modelConnectionUpdateDescriptionLength: analytics.Property<number | undefined> = {
  displayName: 'Model connection update description length',
  name: 'model_connection_update_description_length',
  required: false,
  type: 'number'
}
export const modelConnectionUpdateDirection: analytics.Property<ModelConnectionDirection | undefined> = {
  displayName: 'Model connection update direction',
  name: 'model_connection_update_direction',
  required: false,
  type: 'string'
}
export const modelConnectionUpdateStatus: analytics.Property<ModelConnectionStatus | undefined> = {
  displayName: 'Model connection update status',
  name: 'model_connection_update_status',
  required: false,
  type: 'string'
}
export const modelConnectionUpdateTechnologyNames: analytics.Property<string[] | undefined> = {
  displayName: 'Model connection update technology names',
  name: 'model_connection_update_technology_names',
  required: false,
  type: 'array'
}
export const modelConnectionUpdateTechnologyCount: analytics.Property<number | undefined> = {
  displayName: 'Model connection update technology count',
  name: 'model_connection_update_technology_count',
  required: false,
  type: 'number'
}
export const modelObjectLinkType: analytics.Property<ModelObjectLinkType> = {
  displayName: 'Code link type',
  examples: [
    'azure-devops-branch',
    'azure-devops-file',
    'azure-devops-folder',
    'azure-devops-repo',
    'bitbucket-server-branch',
    'bitbucket-server-file',
    'bitbucket-server-folder',
    'bitbucket-server-repo',
    'github-branch',
    'github-file',
    'github-folder',
    'github-repo',
    'url'
  ],
  name: 'model_object_link_type',
  required: true,
  type: 'string'
}
export const modelDependenciesSearchType: analytics.Property<'incoming' | 'outgoing'> = {
  displayName: 'Model dependencies search type',
  examples: ['incoming', 'outgoing'],
  name: 'model_dependencies_search_type',
  required: true,
  type: 'string'
}
export const modelDependenciesSearchQuery: analytics.Property<string> = {
  displayName: 'Model dependencies search query',
  name: 'model_dependencies_search_query',
  required: true,
  type: 'string'
}

/**
 * Hidden
 */

export const modelOtherDiagramsObjectTypeHidden: analytics.Property<ModelObjectType | 'connection'> = {
  displayName: 'Model other diagrams object type',
  examples: ['actor', 'app', 'group', 'component', 'store', 'system', 'connection'],
  hidden: true,
  name: 'model_other_diagrams_object_type',
  required: true,
  type: 'string'
}
export const modelObjectNameHidden: analytics.Property<string> = {
  displayName: 'Model object name',
  hidden: true,
  name: 'model_object_name',
  required: true,
  type: 'string'
}
