import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'

import customIcons from './helpers/custom-icons'
import icons from './helpers/icons'
import { ModelModule } from './store'

export const install = ({ store, library, vuetify }: { store: Store<any>, library: Library, vuetify: Vuetify }) => {
  const iconDefinitions = [...customIcons, ...icons]
  iconDefinitions.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  store.registerModule(ModelModule.namespace, ModelModule)
}
